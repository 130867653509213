import React, { useState, useMemo, useEffect, useLayoutEffect, useCallback } from 'react';
import { Card } from '@components/Card';
import { Button } from '@atoms';
import { theme } from '@theme';
import MktoForm from '../Form/MktoForm';
import {
  CardDeckWrapper,
  CardDeckGrid,
  ScrollBtnWrapper
} from "./styles/BlogListingCardDeck.styled"
import ComponentPagination from '../Pagination/Pagination';
import { paginator } from '../../utils/paginatorFunction';
import { pushParams } from '../../utils/pushParams';
import queryString from "query-string"
import CustomSelect from '../Select';

export const BlogListingCardDeck = ({ blogPosts, categories, subscribeForm }) => {
  const [showScrollToTop, setShowScrollToTop] = useState(false);
  const [selectedOption, setSelectedOption] = useState({ value: 'All', label: 'All' });
  const [filterParams, setFilterParams] = useState("")
  const [sortedBlogPosts, setSortedBlogPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const maxPostsPerPage = 11;

  useEffect(() => {
    if (blogPosts.length > 0) {
      setSortedBlogPosts(blogPosts)
    }
  }, [blogPosts])

  useEffect(() => {
    if (typeof window !== "undefined") {
      const params = queryString.parse(window.location.search)

      let updatedFilters
      const parseParams = (params) => {
        if (params?.category) {
          updatedFilters = {
            value: params?.category,
            label: params?.category,
          }
        } else {
          updatedFilters = {
            value: 'All',
            label: 'All',
          }
        }
      }

      parseParams(params)
      setSelectedOption(updatedFilters)
    }
  }, [])

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 1000) {
        setShowScrollToTop(true);
      } else {
        setShowScrollToTop(false);
      }
    });
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // for smoothly scrolling
    });
  };


  const selectCompOptions = categories?.sort().reduce((finalArr, initialCategory) => {
    finalArr.push({ value: `${initialCategory}`, label: `${initialCategory}` })
    return finalArr;
  }, [{ value: 'All', label: 'All' }]);

  // styling method for react-select below inspired by: 
  // https://betterprogramming.pub/react-select-makes-creating-selectable-menus-easy-5d9dffc7e0d9

  const selectCustomStyles = useMemo(
    () => ({
      option: (provided, state) => ({
        ...provided,
      }),
      control: (provided) => ({
        ...provided,
        padding: '0px 20px',
        borderRadius: '40px',
      }),
      singleValue: (provided, state) => ({
        ...provided,
      }),
    }),
    []
  );

  useEffect(() => {
    if (selectedOption?.value === 'All') {
      setSortedBlogPosts(blogPosts);
    } else {
      setCurrentPage(1)
      setSortedBlogPosts(blogPosts?.filter(post =>
        post?.node?.category[0]?.title?.includes(selectedOption?.value) ||
        post?.node?.category[1]?.title?.includes(selectedOption?.value) ||
        post?.node?.category[2]?.title?.includes(selectedOption?.value)));
    }
  }, [selectedOption, blogPosts]);

  const paginatedPostData = paginator(sortedBlogPosts, currentPage, maxPostsPerPage);
  paginatedPostData.data.splice(paginatedPostData.data.length - 1, 0, { type: "form" })

  const paginatedPosts = useMemo(() => paginatedPostData.data.map((blogPost) => {
    if (blogPost?.type === "form") {
      return <MktoForm component={subscribeForm} />
    }
    else {
      return (
        <Card
          title={blogPost?.node?.externalTitle || blogPost?.node?.title}
          gatsbyImage={blogPost?.node?.featuredImage?.gatsbyImageData}
          featuredImg={blogPost?.node?.featuredImage?.file?.url}
          description={blogPost?.node?.description?.description}
          categories={blogPost?.node?.category}
          link={blogPost?.node?.slug}
        />
      )
    }
  }), [sortedBlogPosts, currentPage, maxPostsPerPage, subscribeForm]);

  const handleFilterParams = useCallback(() => {
    const noParam = !selectedOption.value || selectedOption.value === 'All'
    const params = noParam ? '' : `category=${selectedOption?.value?.replaceAll('&', '%26')
      }`

    const paramsString = params !== '' ? "?" + params : ""

    setFilterParams(paramsString)
  }, [selectedOption])

  useLayoutEffect(() => {
    handleFilterParams()
  }, [handleFilterParams])

  useEffect(() => {
    pushParams(filterParams)
  }, [filterParams])

  return (
    <CardDeckWrapper id='blog-card-deck' style={{ fontFamily: theme.font.primary }}>
      <CardDeckGrid>
        <CustomSelect
          options={selectCompOptions}
          defaultValue='All'
          value={selectedOption}
          onChange={setSelectedOption}
          placeholder='Select...'
          className="blogSelect"
        />
      </CardDeckGrid>
      <CardDeckGrid>
        {paginatedPosts}
      </CardDeckGrid>
      <ComponentPagination
        current={currentPage}
        total={sortedBlogPosts.length}
        pageSize={maxPostsPerPage}
        func={setCurrentPage}
        marginTop={48}
      />
      <ScrollBtnWrapper showScrollToTop={showScrollToTop} onClick={scrollToTop}>
        <Button ctaVariant='secondary'>Back To Top</Button>
      </ScrollBtnWrapper>
    </CardDeckWrapper>
  );
};

BlogListingCardDeck.displayName = 'BlogListingCardDeck';
