import React from "react"
import { Layout } from "@components/Layout"
import { BlogListingCardDeck } from "@components/Blog/BlogListingCardDeck"
import ConversionPanel from "@components/ConversionPanel/ConversionPanel"
import HeroBlogListing from "@components/Hero/HeroBlogListing"
import { graphql } from "gatsby"
import Seo from "../../components/Seo/Seo"
import { EditorPicksSection } from "@components/Blog/EditorPicksSection"

export default function BlogListingPage({ data }) {
  const {
    contentfulComponentCardDeck,
    contentfulComponentConversionPanel,
    allContentfulBlogPost,
    contentfulPage,
    contentfulComponentForm,
    contentfulComponentHero,
  } = data
  const seo = contentfulPage?.pageSeo
  const blogPosts = allContentfulBlogPost?.edges
  const heroData = contentfulComponentHero
  const heroBlogPosts = [blogPosts[0], blogPosts[1], blogPosts[2]]
  const editorPicksPosts = blogPosts
    .filter(post => post?.node?.editorPick === true)
    .slice(0, 3)
  const cardDeckContent = contentfulComponentCardDeck
  const conversionPanelOne = contentfulComponentConversionPanel
  const blogSubscribeForm = contentfulComponentForm
  const categories = blogPosts.reduce((acc, blog) => {
    const categoryTitle = blog?.node?.category[0]?.title;
     if (categoryTitle && !acc.includes(categoryTitle)) {
       acc.push(categoryTitle);
     }
     return acc;
  }, []);

  return (
    <Layout>
      <Seo
        title={seo?.seoTitle}
        description={seo?.seoDescription}
        noIndex={seo?.noIndex}
        canonical={seo?.canonical}
        metaTags={seo?.metaTags}
      />
      <HeroBlogListing posts={heroBlogPosts} heroData={heroData}/>
      <BlogListingCardDeck
        blogPosts={blogPosts}
        headingSize="H2"
        categories={categories}
        subscribeForm={blogSubscribeForm}
      />
      {cardDeckContent && editorPicksPosts && (
        <EditorPicksSection
          cardDeckContent={cardDeckContent}
          editorPicksPosts={editorPicksPosts}
        />
      )}
      {conversionPanelOne && <ConversionPanel component={conversionPanelOne} />}
    </Layout>
  )
}

export const blogListQuery = graphql`
  query blogListingPageQuery {
    contentfulPage(internalName: { eq: "Blog Listing Page" }) {
      id
      slug
      pageSeo {
        graphImage {
          description
          title
          file {
            url
          }
        }
        seoTitle
        seoDescription
        canonical
        noIndex
        metaTags
        article
      }
    }
    contentfulComponentHero(type: {eq: "blog-listing"}) {
      id
      featuredImage {
        gatsbyImageData
        file {
          url
        }
      }
    }
    contentfulComponentCardDeck(
      id: { eq: "384809b8-a22e-5762-bcb4-e351e1a1acc4" }
    ) {
      id
      internalName
      type
      cardType
      backgroundColor
      kicker
      slide2Kicker
      headingSize
      heading
      slide2Heading
      noTopPadding
      noBottomPadding
      subheading {
        subheading
      }
      slide2Subheading {
        slide2Subheading
      }
      slide2Post {
        ... on ContentfulResource {
          __typename
          id
          title
          alternateTitle
          description {
            description
          }
          category {
            title
            id
          }
          externalUrl
          slug
          featuredImage {
            gatsbyImageData
            title
            url
            description
          }
          infographicImage {
            gatsbyImageData
            file {
              fileName
              url
            }
          }
        }
        ... on ContentfulBlogPost {
          __typename
          id
          title
          alternateTitle
          externalTitle
          publishDate
          slug
          body {
            raw
          }
          description {
            description
          }
          category {
            backgroundColor
            fontColor
            title
            id
          }
          author {
            fullName
            headshot {
              file {
                url
              }
            }
          }
          featuredImage {
            gatsbyImageData
            url
            title
            description
          }
        }
        ... on ContentfulKnowledgeCenterPost {
          __typename
          id
          title
          alternateTitle
          slug
          description {
            description
          }
          image {
            gatsbyImageData
            url
          }
          category {
            title
            id
          }
          body {
            references {
              ... on ContentfulComponentImage {
                id
                internalLink
                url
                primaryImage {
                  gatsbyImageData
                  file {
                    url
                    details {
                      image {
                        height
                        width
                      }
                    }
                  }
                  id
                  title
                  description
                }
              }
            }
          }
        }
        ... on ContentfulNewsroomPost {
          __typename
          id
          alternateTitle
          title
          externalTitle
          publishDate
          slug
          description {
            description
          }
          category {
            title
            id
          }
          featuredImage {
            gatsbyImageData
            url
            title
            description
          }
        }
        ... on ContentfulComponentTile {
          __typename
          internalName
          category {
            title
            id
          }
          heading
          alternateHeading
          headingSize
          subheading
          description {
            description
          }
          ctaLink
          ctaInternalLink
          icon {
            gatsbyImageData
            file {
              url
            }
          }
        }
      }
    }
    contentfulComponentConversionPanel(
      id: { eq: "e7f7a854-e0de-5cbb-b105-4cc23f453f5a" }
    ) {
      id
      textAlign
      alignContent
      background
      kicker
      noTopPadding
      noBottomPadding
      callsToAction {
        contentful_id
        id
        internalLink
        label
        type
        link
        variant
      }
      headingSize
      heading
      subheading {
        raw
      }
      type
    }
    allContentfulBlogPost(
      sort: { fields: publishDate, order: DESC }
      filter: { id: { ne: "8c7ce552-fe16-5084-8505-c662bca6b48a" } }
    ) {
      edges {
        node {
          __typename
          id
          title
          externalTitle
          slug
          publishDate
          editorPick
          category {
            title
            backgroundColor
            fontColor
          }
          featuredImage {
            gatsbyImageData
            file {
              url
            }
          }
          description {
            description
          }
          body {
            raw
          }
        }
      }
    }
    contentfulComponentForm(
      id: { eq: "e6fd7f57-c1ea-55e5-b7d8-980b180265b6" }
    ) {
      __typename
      id
      type
      anchorLinkId
      internalName
      marketoFormId
      background
    }
  }
`
