import React, { useState } from 'react';
import { CardDeckWrapper, KickerWrapper } from '../CardDeck/styles/CardDeck.styled';
import { CardDeckSlide } from '../CardDeck/CardDeck.Slide';

export const EditorPicksSection = ({ editorPicksPosts, cardDeckContent }) => {
  const [activeSlide, setActiveSlide] = useState('first-slide')

  const backgroundColor = cardDeckContent?.backgroundColor
  const headingSize = cardDeckContent?.headingSize
  const cardType = cardDeckContent?.cardType
  
  const slide1Kicker = cardDeckContent?.kicker
  const slide2Kicker = cardDeckContent?.slide2Kicker
  const slide1Header = cardDeckContent?.heading
  const slide2Header = cardDeckContent?.slide2Heading
  const slide1Subheading = cardDeckContent?.subheading?.subheading
  const slide2Subheading = cardDeckContent?.slide2Subheading?.slide2Subheading
  const slide1Cards = editorPicksPosts
  const slide2Cards = cardDeckContent?.slide2Post

  let kickerBackground
  if (backgroundColor === "grey" || backgroundColor === "grey") {
    kickerBackground = 'white'
  } else {
    kickerBackground = '#F2F4F7'
  }

  return (
    <CardDeckWrapper backgroundColor={backgroundColor}>
      <KickerWrapper activeSlide={activeSlide} kickerBackground={kickerBackground}>
        { slide1Kicker && 
          <p onClick={() => {activeSlide !== 'first-slide' && setActiveSlide('first-slide')}} className="first-slide kicker">{slide1Kicker}</p>
        }
        { slide2Kicker && 
          <p onClick={() => {activeSlide !== 'second-slide' && setActiveSlide('second-slide')}} className="second-slide kicker">{slide2Kicker}</p>
        }
      </KickerWrapper>
      <CardDeckSlide 
        className={'first-slide'}
        activeSlide={activeSlide}
        heading={slide1Header} 
        headingSize={headingSize} 
        subheading={slide1Subheading} 
        cards={slide1Cards} 
        cardType={cardType}
      />
      <CardDeckSlide 
        className={'second-slide'}
        activeSlide={activeSlide}
        heading={slide2Header} 
        headingSize={headingSize} 
        subheading={slide2Subheading} 
        cards={slide2Cards} 
        cardType={cardType} 
      />
    </CardDeckWrapper>
  );
};

EditorPicksSection.displayName = 'EditorPicksSection';

