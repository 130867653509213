import styled, { css } from 'styled-components';

export const CardDeckWrapper = styled.div`
  background: #F2F4F7;
  ${({backgroundColor}) => `
    background: ${backgroundColor === 'white' && 'white'};
  `}
  padding: 96px 0;
  font-family: Roboto, sans-serif;
  @media (max-width: 992px) {
    padding: 56px 0;
  }
  @media (max-width: 768px) {
    padding: 40px 0;
  }
  ${({ noTopPadding, noBottomPadding }) => css`
    padding-top: ${noTopPadding === true && 0};
    padding-bottom: ${noBottomPadding === true && 0};
      @media (max-width: 992px) {
        padding-top: ${noTopPadding === true && 0};
        padding-bottom: ${noBottomPadding === true && 0};
      }
      @media (max-width: 768px) {
        padding-top: ${noTopPadding === true && 0};
        padding-bottom: ${noBottomPadding === true && 0};
      }
  `}
  .hide {
    display: none;
  }
`
  
export const KickerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 64px;
  .kicker {
    min-width: 150px;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    font-weight: 700;
    color: black;
    padding: 20px 24px;
    transition: all 0.3s ease;
    cursor: pointer;
    &.first-slide {
      border-radius: 30px 0px 0px 30px;
      ${({activeSlide, kickerBackground}) => `
        background: ${activeSlide === 'first-slide' ? '#002dc2' : `${kickerBackground}`};
        color: ${activeSlide === 'first-slide' ? 'white' : 'black' };
      `}
    }
    &.second-slide {
      border-radius: 0px 30px 30px 0px;
      ${({activeSlide, kickerBackground}) => `
        background: ${activeSlide === 'second-slide' ? '#002dc2' : `${kickerBackground}` };
        color: ${activeSlide === 'second-slide' ? 'white' : 'black' };
      `}
    }
  }
`

export const CardContent = styled.div`
  margin: 0 auto;
  padding: 0 15px;
  max-width: 1170px;
  @media (max-width: 992px) {
    max-width: 770px;
  }
  @media (max-width: 768px) {
    max-width: 506px;
  }
  .single-slide-kicker {
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-align: center;
    padding-bottom: 8px;
    font-weight: 700;
  }
  .heading {
    text-align: center;
    padding-bottom: 32px;
    margin: 0 auto;
    max-width: 770px;
    @media (min-width: 577px) {
      padding-bottom: 40px;
    }
  }
  .subheading {
    font-size: 22px;
    color: #4D4D4D;
    text-align: center;
    padding-bottom: 48px;
    margin: 0 auto;
    max-width: 770px;
  }
`

export const CardDeckGrid = styled.div`
  margin: 0 auto;
  display: grid;
  grid-gap: 32px;
  grid-template-columns: 1fr 1fr 1fr;
  max-width: 1200px;
  @media (max-width: 992px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`