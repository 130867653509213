import React from 'react';
import { Card, TextOnHoverCard } from '@components/Card';
import { Heading } from '@atoms';
import { CardContent, CardDeckGrid } from './styles/CardDeck.styled';
import { theme } from '@theme';
import { getCardSlug } from '../../utils/getCardSlug';

export const CardDeckSlide = ({ activeSlide, heading, headingSize, subheading, kicker, cards, cardType, className }) => {
  let activeClass 
  if (activeSlide === className) {
    activeClass = 'show'
  } else {
    activeClass = 'hide'
  }
  
  return(
    <CardContent className={`${activeClass} ${className}`}>
      { kicker && 
        <p className="single-slide-kicker" style={{ color: theme?.colors?.primary }}>{kicker}</p>
      }
      { heading && 
        <Heading 
          headingSize={headingSize || "H2"}
          className="heading"
        >
          {heading}
        </Heading>
      }
      { subheading && 
        <p className="subheading">{subheading}</p>
      }
      <CardDeckGrid>
        { cards && cards?.map(card => {
          let link = getCardSlug(card)
          let infographicImage = card?.infographicImage?.file?.url
          let gatsbyImage = card?.infographicImage?.gatsbyImageData 

          if (cardType === "Reveal Text on Hover") {
            return (
              <TextOnHoverCard
                title={card?.title || card?.heading}
                alternateTitle={card?.alternateTitle || card?.alternateHeading}
                description={card?.description?.description}
                link={link}
                gatsbyImage={gatsbyImage || card?.featuredImage?.gatsbyImageData || card?.image?.gatsbyImageData || card?.icon?.gatsbyImageData || card?.node?.featuredImage?.gatsbyImageData}
                featuredImg={infographicImage || card?.featuredImage?.url || card?.image?.url}
                categories={card?.category}
                alt={card?.featuredImage?.description || card?.image?.description}
              />
            );
          } else {
            return (
              <Card
                title={card?.title || card?.heading || card?.node?.title}
                tag={card?.tag}
                description={card?.description?.description || card?.node?.description?.description}
                author={card?.author}
                link={link}
                gatsbyImage={gatsbyImage || card?.featuredImage?.gatsbyImageData || card?.image?.gatsbyImageData || card?.icon?.gatsbyImageData || card?.node?.featuredImage?.gatsbyImageData}
                featuredImg={infographicImage || card?.featuredImage?.url || card?.image?.url || card?.icon?.file?.url || card?.node?.featuredImage?.file?.url}
                categories={card?.category || card?.node?.category}
                blogBody={card?.body || card?.node?.body}
                alt={card?.featuredImage?.description || card?.image?.description}
              />
            )
          }
        })}
      </CardDeckGrid>
    </CardContent>
  )
}