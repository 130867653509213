import styled from "styled-components"

export const CardDeckWrapper = styled.div`
  background: #F0F0F0;
  padding: 56px 60px 140px 60px;
  @media (max-width: 992px) {
    padding: 80px 40px;
  }
  @media (max-width: 600px) {
    padding: 60px 20px;
  }
`

export const CardDeckGrid = styled.div`
  padding-bottom: 64px;
  margin: 0 auto;
  display: grid;
  grid-gap: 32px;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  max-width: 1200px;
  .blogSelect {
    grid-area: 1 / 3 / 2 / 3;
    margin-bottom: -32px;
    align-items: end;
  }
  @media (max-width: 1080px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    .blogSelect {
      width: 370px;
    }
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    .blogSelect { 
      grid-area: 1 / 1 / 1 / 1;
      width: 100%;
    }
  }
`

export const ScrollBtnWrapper = styled.div`
  position: sticky;
  text-align: right;
  bottom: 20px;
  margin-top: -45px;
  transition: opacity 400ms ease, visibility 400ms ease;
  width: fit-content;
  float: right;
  button {
    transition: filter 200ms ease;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 10px!important;
    line-height: 10px!important;
    padding: 14px 15px !important;
    font-weight: 700;
    min-width: unset!important;
    height: unset!important;
  } 
  @media (max-width: 768px) {
    margin-top: -100px;
  }
  ${({ showScrollToTop }) =>
  `
    visibility: ${ showScrollToTop ? 'visible' : 'hidden' };
    opacity: ${ showScrollToTop ? '100%' : '0%' };
  `}
`
